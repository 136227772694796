import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import About from './pages/About';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import ProductDetail from './pages/ProductDetail';
import Products from './pages/Products';
import MyApp from './components/MyApp';
import ReactMemo from './pages/ReactMemo';
import Todos from './pages/Todos';

export const UserContext = React.createContext();

const user = { username: 'Tung Pham' };

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <FunctionComponent title="Demo Function" />
    <ClassComponent title="Demo Class" />
    <ProductDetailsComponent title="iPhone 15 Pro Max" price={1000} content="Apple Smartphone" />
    <ProductDetailsComponent price={999} content={0} />
    <CounterComponent />
    <CalculatorComponent /> */}
    <UserContext.Provider value={user}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<Home />}></Route>
            <Route path="about" element={<About />}></Route>
            <Route path="login" element={<Login />}></Route>
            <Route path="sign-up" element={<SignUp />}></Route>
            <Route path="demo" element={<MyApp />}></Route>
            <Route path="react-memo" element={<ReactMemo />}></Route>
            <Route path="todos" element={<Todos />}></Route>
            <Route path="products">
              <Route index element={<Products />}></Route>
              <Route path=":productId" element={<ProductDetail />}></Route>
            </Route>
            <Route path="*" element={<NotFound />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </UserContext.Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
