import React from 'react';
import { Button, Table, Container } from 'react-bootstrap';

export default function ProductListComponent({ products, removeProduct }) {
  const handleDeleteProduct = (product) => {
    // alert(`Product ID: ${product.id}`);
    removeProduct(product.id);
  };
  return (
    <Container>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Description</th>
            <th>Price</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => (
            <tr key={product.id}>
              <td>{product.id}</td>
              <td>{product.name}</td>
              <td>{product.content}</td>
              <td>{product.price} $</td>
              <td>{product.status}</td>
              <td>
                <Button variant="dark" onClick={() => handleDeleteProduct(product)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}
