import React, { useEffect } from 'react';

export default function Home() {
  useEffect(() => {
    console.log('Component Did Mount');
    return () => {
      console.log('Component Will Unmount');
    };
  }, []);
  return <div>Home</div>;
}
