import React from 'react';
import styles from './Footer.module.scss';
import { Container } from 'react-bootstrap';

export default function Footer() {
  return (
    <footer className={styles.container}>
      <Container>
        <ul>
          <li> &copy; 2024 Phạm Tùng</li>
          <li>
            <a href="/">Help</a>
          </li>
          <li>
            <a href="/">Contact</a>
          </li>
          <li>
            <a href="/">Privacy policy</a>
          </li>
        </ul>
      </Container>
    </footer>
  );
}
