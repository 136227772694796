import React from 'react';
import { useParams } from 'react-router-dom';

export default function ProductDetail(props) {
  const { productId } = useParams();

  return (
    <div>
      <h1>Product ID: {productId}</h1>
    </div>
  );
}
