import React, { useEffect } from 'react';
import { useFetch } from '../hooks/custom/useFetch';

export default function Todos() {
  useEffect(() => {
    fetch('https://jsonplaceholder.typicode.com/todos')
      .then((response) => response.json())
      .then((data) => console.log(data));
  }, []);

  const [data] = useFetch('https://jsonplaceholder.typicode.com/users');

  console.log(data);
  return <div>Todos</div>;
}
