import React, { useEffect, useState } from 'react';
import { Container, Nav, Row, Table } from 'react-bootstrap';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import './Products.scss';
import queryString from 'query-string';

export default function Products() {
  const products = [
    { id: 1, name: 'iPhone 15 Pro Max', price: 1000, content: 'Apple Smartphone', status: 'Active' },
    { id: 2, name: 'iPhone 14 Pro Max', price: 800, content: 'Apple Smartphone', status: 'Inactive' },
    { id: 3, name: 'iPhone 13 Pro Max', price: 600, content: 'Apple Smartphone', status: 'New' },
    { id: 4, name: 'iPhone 12 Pro Max', price: 1000, content: 'Apple Smartphone', status: 'New' },
    { id: 5, name: 'iPhone 11 Pro Max', price: 800, content: 'Apple Smartphone', status: 'Inactive' },
    { id: 6, name: 'Galaxy S24 Ultra', price: 600, content: 'Samsung Smartphone', status: 'Active' },
    { id: 7, name: 'Galaxy S23 Ultra', price: 1000, content: 'Samsung Smartphone', status: 'Inactive' },
    { id: 8, name: 'Galaxy S22 Ultra', price: 800, content: 'Samsung Smartphone', status: 'Active' },
    { id: 9, name: 'Galaxy S21 Ultra', price: 600, content: 'Samsung Smartphone', status: 'New' },
  ];
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [filteredStatus, setFilteredStatus] = useState('All');

  useEffect(() => {
    const params = queryString.parse(location.search);
    setFilteredStatus(params.status || 'All');
  }, [location.search]);

  const handleShowAll = () => {
    setFilteredStatus('All');
    setSearchParams({ status: 'All' });
  };
  const handleShowActive = () => {
    setFilteredStatus('Active');
    setSearchParams({ status: 'Active' });
  };
  const handleShowInactive = () => {
    setFilteredStatus('Inactive');
    setSearchParams({ status: 'Inactive' });
  };
  const handleShowNew = () => {
    setFilteredStatus('New');
    setSearchParams({ status: 'New' });
  };

  const updatedProducts = products.filter((product) => filteredStatus === 'All' || filteredStatus === product.status);

  return (
    <Container>
      <Row col={8}>
        <h1>Products</h1>
        <Nav variant="pills" defaultActiveKey="#" className="mb-4">
          {[
            { status: 'All', handleTabClick: handleShowAll, label: 'Show All' },
            { status: 'Active', handleTabClick: handleShowActive, label: 'Show Active' },
            { status: 'Inactive', handleTabClick: handleShowInactive, label: 'Show Inactive' },
            { status: 'New', handleTabClick: handleShowNew, label: 'Show New' },
          ].map(({ status, handleTabClick, label }, index) => (
            <Nav.Item key={index}>
              <Nav.Link
                className={filteredStatus === status ? 'active' : ''}
                onClick={handleTabClick}
                eventKey={`link-${index}`}
              >
                {label}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Description</th>
              <th>Price</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {updatedProducts.map((product, index) => (
              <tr key={product.id}>
                <td>{product.id}</td>
                <td>{product.name}</td>
                <td>{product.content}</td>
                <td>${product.price}</td>
                <td>{product.status}</td>
                <td>
                  <Link to={`/products/${product.id}`}>View</Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </Container>
  );
}
