import React from 'react';
import SampleReactMemo from '../components/SampleReactMemo';

export default function ReactMemo() {
  return (
    <div>
      <SampleReactMemo txt="Number 1, number 2, number 3, etc" />
    </div>
  );
}
