import React, { useContext } from 'react';
import './Header.scss';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { MdHome, MdPersonAdd, MdLogin } from 'react-icons/md';
import { UserContext } from '..';

export default function Header() {
  const user = useContext(UserContext);
  return (
    <Navbar expand="lg" className="bg-body-tertiary" bg="dark" data-bs-theme="dark">
      <Container>
        <NavLink to="/" className="navbar-brand">
          Welcome{user && ', ' + user.username}
        </NavLink>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavLink to="/" className="nav-link">
              <MdHome style={{ fontSize: '22px', marginTop: '-5px' }} />
              Home
            </NavLink>
            <NavLink to="/products" className="nav-link">
              Products
            </NavLink>
            <NavLink to="/demo" className="nav-link">
              Demo
            </NavLink>
            <NavLink to="/react-memo" className="nav-link">
              React Memo
            </NavLink>
            <NavLink to="/todos" className="nav-link">
              Todos
            </NavLink>
          </Nav>
          <Nav>
            <NavLink to="/login" className="nav-link">
              <MdLogin style={{ fontSize: '22px', marginTop: '-5px' }} /> Login
            </NavLink>
            <NavLink to="/sign-up" className="nav-link">
              <MdPersonAdd style={{ fontSize: '22px', marginTop: '-5px' }} /> Register
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
