import React, { useState } from 'react';
import { Button, Table, Container, Form } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';

function AddProductForm({ addNewProduct }) {
  const [product, setProduct] = useState({ name: '', price: '', content: '', status: '' });
  const [errors, setErrors] = useState({});

  const handleChange = (field) => (event) => {
    const { value } = event.target;
    setProduct({ ...product, [field]: value });
    // Clear validation error when the user starts typing
    setErrors({ ...errors, [field]: '' });
  };

  const validateForm = () => {
    let formIsValid = true;
    const newErrors = {};

    if (!product.name.trim()) {
      newErrors.name = 'Name is required';
      formIsValid = false;
    }

    if (!product.price.trim() || isNaN(product.price) || parseFloat(product.price) <= 0) {
      newErrors.price = 'Price must be a valid positive number';
      formIsValid = false;
    }

    if (!product.content.trim()) {
      newErrors.content = 'Content is required';
      formIsValid = false;
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleAddProduct = (event) => {
    event.preventDefault();

    if (validateForm()) {
      const newProduct = { id: Math.floor(Math.random() * 100), ...product };
      addNewProduct(newProduct);
      setProduct({ name: '', price: '', content: '', status: '' });
    }
  };
  console.log('Add Product Component');
  return (
    <Container>
      <form className="w-50 mx-auto">
        <Table striped bordered hover size="sm">
          <tbody>
            <tr>
              <td>Name: </td>
              <td>
                <Form.Control size="lg" type="text" value={product.name} onChange={handleChange('name')} required />
              </td>
            </tr>
            <tr>
              <td>Price: </td>
              <td>
                <Form.Control size="lg" type="number" value={product.price} onChange={handleChange('price')} required />
              </td>
            </tr>
            <tr>
              <td>Content: </td>
              <td>
                <Form.Control
                  size="lg"
                  type="text"
                  value={product.content}
                  onChange={handleChange('content')}
                  required
                />
              </td>
            </tr>
            <tr>
              <td>Status:</td>
              <td>
                <Form.Select aria-label="select status" onChange={handleChange('status')} required>
                  <option>Select a status</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                  <option value="New">New</option>
                </Form.Select>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <Button variant="dark" onClick={handleAddProduct}>
                  <FaPlus />
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </form>
      <div style={{ marginTop: '10px' }}>
        {Object.values(errors).map((error, index) => (
          <span key={index} style={{ color: 'red' }}>
            {error}
            <br />
          </span>
        ))}
      </div>
    </Container>
  );
}
export default React.memo(AddProductForm);
