import React, { useCallback, useMemo, useState } from 'react';
import '../App.css';
import ProductListComponent from './ProductListComponent';
import AddProductForm from './AddProductForm';
import { Button } from 'react-bootstrap';
import SampleReactMemo from './SampleReactMemo';

export default function MyApp() {
  const [showForm, setShowForm] = useState(true);
  const toggleFormVisibility = () => {
    setShowForm(!showForm);
  };

  const initialProducts = [
    { id: 1, name: 'iPhone 15 Pro Max', price: 1000, content: 'Apple Smartphone', status: 'Active' },
    { id: 2, name: 'iPhone 14 Pro Max', price: 800, content: 'Apple Smartphone', status: 'Inactive' },
    { id: 3, name: 'iPhone 13 Pro Max', price: 600, content: 'Apple Smartphone', status: 'New' },
    { id: 4, name: 'iPhone 12 Pro Max', price: 1000, content: 'Apple Smartphone', status: 'New' },
    { id: 5, name: 'iPhone 11 Pro Max', price: 800, content: 'Apple Smartphone', status: 'Inactive' },
    { id: 6, name: 'Galaxy S24 Ultra', price: 600, content: 'Samsung Smartphone', status: 'Active' },
    { id: 7, name: 'Galaxy S23 Ultra', price: 1000, content: 'Samsung Smartphone', status: 'Inactive' },
    { id: 8, name: 'Galaxy S22 Ultra', price: 800, content: 'Samsung Smartphone', status: 'Active' },
    { id: 9, name: 'Galaxy S21 Ultra', price: 600, content: 'Samsung Smartphone', status: 'New' },
  ];
  const [products, setProducts] = useState(initialProducts);

  const addNewProduct = useCallback((product) => {
    setProducts((products) => [...products, product]);
    // console.log('Check product from parent: ', product);
  }, []);

  const removeProduct = (id) => {
    console.log('Product ID: ', id);
    const updatedProducts = products.filter((product) => product.id !== id);
    setProducts(updatedProducts);
  };

  const bigData = useMemo(() => {
    console.log('Render big data');
    let sum = 0;
    for (let i = 0; i < 1234567890; i++) {
      sum += i;
    }
    return sum;
  }, []);

  return (
    <>
      <div className="App">
        <h1>Products Management</h1>
        <div>
          <Button variant="dark" onClick={toggleFormVisibility}>
            {showForm ? 'Hide' : 'Show'}
          </Button>
          {showForm && <AddProductForm addNewProduct={addNewProduct} />}
        </div>
        <ProductListComponent products={products} removeProduct={removeProduct} />
        <SampleReactMemo txt="one, two, three" />
        <p>Big Data is: {bigData}</p>
      </div>
    </>
  );
}
