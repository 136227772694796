import React, { useState } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle login logic here, such as sending the email and password to the server
    console.log('Email:', email);
    console.log('Password:', password);
    // You can also reset the form fields here if needed
    let user = { email };
    if (user) {
      navigate('/products', { replace: true });
    }
    setEmail('');
    setPassword('');
  };

  return (
    <Container className="w-50">
      <h1>Login</h1>
      <Form onSubmit={handleSubmit} className="w-100 mx-auto my-3">
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" placeholder="Enter email" value={email} onChange={handleEmailChange} />
          <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text>
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" placeholder="Password" value={password} onChange={handlePasswordChange} />
        </Form.Group>

        <Button variant="secondary" type="submit" className="mt-3">
          Submit
        </Button>
      </Form>
    </Container>
  );
}
