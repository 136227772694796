import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // If you're using React Router

export default function NotFoundPage() {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={6} className="text-center">
          <h1>404 - Page Not Found</h1>
          <p>The page you're looking for doesn't exist.</p>
          <Link to="/">
            <Button variant="secondary">Go Home</Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}
